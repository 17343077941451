<template>
  <v-card>
    <v-card-text>
      <v-btn
        class="float-right"
        to="/fkadmin"
      >
        Admin Dashboard
      </v-btn>
      <h2>Prize Claims</h2>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="prizeClaims"
      item-key="uid"
      class="elevation-1"
      :search="search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.userUid="{ item }">
        {{ item.name }}<br>
        <small>({{ item.userUid }})</small>
      </template>
      <template v-slot:item.created="{ item }">
        <span v-if="item.created">
          {{ item.created.seconds|moment("dddd MMM Do") }}
        </span>
      </template>
      <template v-slot:item.claimedDate="{ item }">
        <span v-if="item.claimedDate">
          {{ item.claimedDate.seconds|moment("dddd MMM Do") }}
        </span>
      </template>
      <template v-slot:item.id="{ item }">
        <v-btn
          v-if="!item.claimedDate"
          :class="`claim-${item.id}`"
          color="error"
          small
          outlined
          @click="deleteClaim(item)"
        >
          Delete
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import firebase from 'firebase/app'
import dbFunctions from '@/db'

export default {
  data() {
    return {
      search: '',
      sortBy: 'created',
      sortDesc: true,
      prizeClaims: [],
    }
  },
  computed: {
    headers() {
      return [
        {text: 'Member', value: 'userUid'},
        {text: 'Event', value: 'event'},
        {text: 'Silver KombatCoins', value: 'silverCoins'},
        {text: 'Gold KombatCoins', value: 'goldCoins'},
        {text: 'Desc', value: 'desc'},
        {text: 'Created', value: 'created'},
        {text: 'Claimed', value: 'claimed'},
        {text: 'Claimed Date', value: 'claimedDate'},
        {text: 'Delete', value: 'id'},
      ]
    },
  },
  mounted() {
    this.getClaims()
  },
  methods: {
    getClaims() {
      dbFunctions.getClaims().then(data => {
        this.prizeClaims = data
        for (let i = 0; i < this.prizeClaims.length; i += 1) {
          this.userItem(this.prizeClaims[i].userUid, i)
        }
      })
    },
    deleteClaim(item) {
      console.log(item.id)
      dbFunctions.deleteClaim(item.id).then(claim => {
        console.log(claim)
        this.prizeClaims.splice(this.prizeClaims.findIndex(e => e.id === item.id), 1)
      })
    },
    userItem(userUid, i) {
      let memberData = {}
      const member = firebase.functions().httpsCallable('getUser')
      member({uid: userUid})
        .then(user => {
          memberData = user
          this.prizeClaims[i].name = memberData.data.displayName

          return memberData.data.displayName
        })

      return memberData
    },
  },
}
</script>

<style lang="scss">
</style>
