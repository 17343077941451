var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-btn',{staticClass:"float-right",attrs:{"to":"/fkadmin"}},[_vm._v(" Admin Dashboard ")]),_c('h2',[_vm._v("Prize Claims")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.prizeClaims,"item-key":"uid","search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.userUid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)),_c('br'),_c('small',[_vm._v("("+_vm._s(item.userUid)+")")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [(item.created)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created.seconds,"dddd MMM Do"))+" ")]):_vm._e()]}},{key:"item.claimedDate",fn:function(ref){
var item = ref.item;
return [(item.claimedDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.claimedDate.seconds,"dddd MMM Do"))+" ")]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(!item.claimedDate)?_c('v-btn',{class:("claim-" + (item.id)),attrs:{"color":"error","small":"","outlined":""},on:{"click":function($event){return _vm.deleteClaim(item)}}},[_vm._v(" Delete ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }